// fontawesome
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/brands.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";

// App
import "./assets/scss/app.scss";

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import DatePicker from "vue2-datepicker";
import VTooltip from "v-tooltip";
import audit from "@/mixins/audit";

import { Loader } from "@googlemaps/js-api-loader"

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

// Global error handling mixin
import HandleErrorMixin from './mixins/handleErrorMixin';
Vue.mixin(HandleErrorMixin)

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Vue.config.productionTip = false;

Vue.use(Toast);

import axios from "axios";
import GmapVue from 'gmap-vue'
import vuePusher from 'vue-pusher'

Vue.use(DatePicker);
Vue.use(VTooltip, {
  themes: {
    main: {
      $extend: "tooltip",
      $resetCss: true,
      html: true,
      delay: { show: 1000 },
    },
  },
});
Vue.mixin(audit);
new Vue({
  router,
  store,
  async mounted() {
    const response = await axios.get("/config.json?v2");

    this.unwatchToken = this.$watch('$store.getters.accessToken', async newVal => {
      const { accessToken } = store.getters;
      const { api_google_maps_key, pusher } = store.getters.settings;
      
      const loader = new Loader({
        apiKey: api_google_maps_key,
        libraries: ['places', 'geometry'],
        version: 'quarterly'
      });
      loader.load();
      Vue.use(GmapVue);
      Vue.use(vuePusher, {
        api_key: pusher.key,
        options: {
          cluster: pusher.cluster,
          logToConsole: response.data.PUSHER_LOGTOCONSOLE,
          authEndpoint: response.data.APP_API_URL + "broadcasting/auth",
          wsHost: pusher.host,
          wssHost: pusher.host,
          wsPort: pusher.port,
          wssPort: pusher.port,
          forceTLS: pusher.forcetls,
          enabledTransports: ["ws", "wss"],
          auth: {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${accessToken || JSON.parse(localStorage.getItem('user')).token}`,
            },
          },
        },
      });
      this.unwatchToken();
      store.dispatch('trackSettingsUpdate');
    });
  },
  render: (h) => h(App),
}).$mount("#app");
