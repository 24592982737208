import Vue from "vue";
import Vuex from "vuex";
import apiLocal from "../apiLocal";
import api from "../api";
import user from "./modules/user";
import vehicle from "./modules/vehicle";
import map from "./modules/map";
import mapInfoBlock from "./modules/mapInfoBlock";
import EMYMarkers from "./modules/EMYMarkers";
import report from "./modules/report";
import SOSMarker from './modules/SOSMarker';
import EMS from './modules/EMS';
import errorMixin from '../mixins/handleErrorMixin';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        config: [],
        settings: [],
        locales: [],
        locale: "lv",
        translations: [],
        intervals: [],
        appSidebar: true,
        userTimezone: "",
        whoWasTherePeriod: [],
        googleApiKey: null
    },
    actions: {
        setWhoWasChartPeriod(context, period) {
            context.commit('setWhoWasChartPeriod', period)
        },
        async getConfig(context) {
            await apiLocal.get("/config.json?v2")
                .then((response) => {
                    context.commit("setConfig", response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async getSettings(context) {
            await api.get("settings")
                .then((response) => {
                    const settings = response.data.data;

                    context.commit("setSettings", settings);

                })
                .then(() => {
                    context.commit(
                        "clusterState",
                        context.state.settings.sm_object_clustering_by_default
                    );
                })
                .catch((error) => {
                    errorMixin.methods.handleErrorMixin(error)
                });
        },
        async trackSettingsUpdate(context) {
            let channel = this._vm.pusher.pusher.subscribe(`private-settings-updated`);
            channel.bind("settings-updated", (data) => {
                context.dispatch("updateSettings", data);
            });
        },
        async updateSettings(context, data) {
            let settings = context.state.settings;
            for (let key in data) {
                settings[key] = data[key];
            }
            context.commit("setSettings", settings);
        },
        async getTranslations(context, data) {
            const language = data;
            await api.get("translations", {
                    params: {
                        lang: language,
                    },
                })
                .then((response) => {
                    context.commit("setTranslations", response.data.data);
                })
                .catch((error) => {
                    errorMixin.methods.handleErrorMixin(error)
                });
        },
        addInterval(context, data) {
            context.commit("addInterval", data);
        },
        removeAllIntervals(context) {
            const intervals = context.getters.intervals;
            for (let i = 0; i < intervals.length; i++) {
                const interval = intervals[i];
                clearInterval(interval);
            }
            context.commit("removeAllIntervals");
        },
        toggleAppSidebar(context) {
            context.commit('toggleSidebar')
        }
    },
    mutations: {
        setWhoWasChartPeriod(state, period) {
            state.whoWasTherePeriod = period;
        },
        setUserTimezone(state, timezone) {
          state.userTimezone = timezone;
        },
        setSettings(state, data) {
            state.settings = data;
        },
        setTranslations(state, data) {
            state.translations = data;
        },
        addInterval(state, data) {
            state.intervals.push(data);
        },
        removeAllIntervals(state) {
            state.intervals = [];
        },
        toggleSidebar(state) {
            state.appSidebar = !state.appSidebar;
        },
        setConfig(state, data) {
            state.config = data;
        }
    },
    getters: {
        getWhoWasChartPeriod(state) {
            return state.whoWasTherePeriod
        },
        getUserTimezone(state) {
            return state.userTimezone;
        },
        config(state) {
            return state.config;
        },
        settings(state) {
            return state.settings;
        },
        locale(state) {
            return state.locale;
        },
        translations(state) {
            return state.translations;
        },
        getTranslationByKey(state) {
            return function(key) {
                const keys = key.split(".");
                let current = state.translations;

                for (const k of keys) {
                    if(current[k]) {
                        current = current[k];
                    } else {
                        return key;
                    }
                }

                return current;
            }
        },
        intervals(state) {
            return state.intervals;
        },
        showSidebar(state) {
            return state.appSidebar
        },
    },
    modules: {
        user,
        vehicle,
        map,
        mapInfoBlock,
        report,
        EMYMarkers,
        SOSMarker,
        EMS
    },
});
