<template>
    <div class="video-title-and-close">
        <h6 class="video-title px-1 mb-0 font-weight-bold">
            <i class="fa fa-car mr-1"></i>
            <span>{{ stream.title }}</span>
        </h6>
        <button 
            class="btn btn-light btn-sm mr-1"
            @click="requestSound"
            v-show="stream.src && stream.status_name !== 'new'"
        >
            <i class="fas" :class="[isAudioMuted ? 'fa-microphone-slash': 'fa-microphone']"></i>
        </button>
        <button 
            class="btn btn-light btn-sm mr-0"
            v-if="stream.link"
            @click="deleteStream(stream.link)" 
        >
            <i class="fas mr-0 video-close" :class="isClosing"></i>
        </button>
    </div>
</template>

<script>
    import api from '../../api';
    import { EventBus } from '../../bus';
    import Swal from 'sweetalert2';
    import { mapMutations, mapActions, mapGetters } from 'vuex';
    export default {
        props: {
            stream: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                closeLoading: false
            }
        },
        computed: {
            ...mapGetters(["getTranslationByKey"]),
            isAudioMuted: {
                get() {
                    return this.stream.is_audio_muted
                },
                set(newValue) {
                    if (newValue == true) {
                        this.$audit({
                            action: "stream_mute",
                            value: {
                                stream_id: this.stream.id,
                                muted: newValue,
                            },
                        });
                    }

                    localStorage.setItem(this.stream.id + "_isAudioMuted", newValue);
                    return this.stream.is_audio_muted = newValue;
                }
            },
            isClosing() {
                return {
                    'fa-spinner': this.closeLoading,
                    'fa-spin': this.closeLoading,
                    'fa-times': !this.closeLoading
                }
            }
        },
        methods: {
            ...mapMutations(["removeStream"]),
            ...mapActions(["WSStreamUnsubscribe", "replaceStream"]),
            async deleteStream(url) {
                this.closeLoading = true;
                if (url === 'undefined') {
                    return;
                }
                try {
                    const response = await api.delete(url);
                    const responseStreamID = response.data.stream.id;
                    // emit to StreamCustomVideo
                    EventBus.$emit('delete-stream', responseStreamID);
                    this.closeLoading = false;
                    
                } catch (e) {
                    const errorCode = e.response.status.toString();
                    if (errorCode.startsWith('4')) {
                        this.removeStream(this.stream.id);
                    }else {
                        this.handleErrorMixin(e);
                    }
                    this.closeLoading = false;
                }
            },
           
            async requestSound() {
                if (this.isAudioMuted === true) {
                    Swal.fire({
                        title: this.getTranslationByKey('vehicles.stream.requesting_audio'),
                        text: this.stream.title,
                        position: 'top',
                        icon: 'question',
                        showConfirmButton: true,
                        showCancelButton: true,
                        width: '25rem',
                        confirmButtonColor: 'green',
                        confirmButtonText: this.getTranslationByKey('vehicles.stream.ok'),
                        cancelButtonText: this.getTranslationByKey('vehicles.stream.close'),
                        customClass: {
                            confirmButton: 'btn',
                            cancelButton: 'btn',
                            icon: 'swal-custom-icon'
                        }
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                          this.isAudioMuted = false;
                        }
                    })
                } else {
                  this.isAudioMuted = true;
                }
            }
        }   
    }
</script>